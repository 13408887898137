import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { SliceZones } from '../components/slices/slice-zones'
import Layout from '../components/global/layout'
import Masthead from '../components/layout/masthead'
import CustomerStoriesRelated from '../components/customer-stories/related-articles'

const CustomerStoriesArticle = ({ data, pageContext }) => {
  if (!data) 
    return null

  const document = data.prismicCustomerStory
  const documentsByTag = data.allPrismicCustomerStory.nodes
  
  return (
    <Layout pageTitle={document.data.title.text} 
            metaTitle={document.data.meta_title.text} 
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <Masthead subtitle={document.data.subtitle.text}
                title={document.data.title.text}
                summary={document.data.summary.text} />
      <SliceZones slices={document.data.body} />
      <CustomerStoriesRelated articles={documentsByTag} />
    </Layout>
  )
}

export const query = graphql`
  query CustomerStoriesArticleQuery($id: String, $tags: [String]) {
    prismicCustomerStory(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        title {
          text
        }
        subtitle {
          text
        }
        summary {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        body {
          ... on PrismicCustomerStoryDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCustomerStoryDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
    allPrismicCustomerStory (
      limit: 10
      filter: {tags: {in: $tags}, id: {ne: $id}}
    ) {
      nodes {
        data {
          summary {
            text
          }
          title {
            text
          }
          listing_image {
            alt
            url
          }
        }
        url
        tags
      }
    }
  }
`

export default withPrismicPreview(CustomerStoriesArticle, repositoryConfigs)